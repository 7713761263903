import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import { StockComponent } from './stock/stock.component';
import {
  API_URL_ITEM,
  API_URL_SEARCH_ITEM,
  API_URL_INPUT_ITEM,
} from 'manager/http-constants';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { ITEM_CONSTANT } from './constant';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { SESSION_KEY } from 'manager/environment';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['../master.component.scss'],
  providers: [ConfirmationService, MessageService],
})

/**
 * 販促資材マスタ画面
 */
export class ItemComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(ItemDetailComponent)
  itemDetailComponent: ItemDetailComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  /** 入庫登録、在庫調整 */
  @ViewChild(StockComponent)
  stockComponent: StockComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ITEM;

  // 検索項目生成テンプレートID
  // public searchTemplateId: number = ITEM_TEMPLATE.SEARCH_TEMPLATE_ID;
  public searchTemplateId: number = 
    (this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OFFICE || this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OTHER)
    ? 
      ITEM_TEMPLATE.OFFICE_OTHER_SEARCH_TEMPLATE_ID
    : 
      ITEM_TEMPLATE.SEARCH_TEMPLATE_ID;
  // 一覧項目テンプレートID
  public searchResultsTemplateId: number = 
    (this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OFFICE || this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OTHER)
    ? 
      ITEM_TEMPLATE.OFFICE_OTHER_SEARCH_RESULTS_TEMPLATE_ID
    : 
      ITEM_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any = [];

  // 画面検索結果一覧格納先
  searchResultsList: any = [];

  // ページ数
  pages: number = 0;

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private exportFileService: ExportFileService
  ) {}

  ngOnInit() {
    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      // .getHeaderList(ITEM_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .getHeaderList(this.searchResultsTemplateId)
      .subscribe((response) => {
        // TODO 都庁案件で下記項目は画面に表示しない為、データから削除
        response.body.forEach((item) => {
          if (
            !(
              item.field == 'delete_datetime' ||
              item.field == 'create_department_code'
            )
          ) {
            this.columnOrder.push(item);
          }
        });
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getForkJoinData(
        ITEM_CONSTANT.TARGET_TABLE,
        API_URL_ITEM,
        this.searchResultsTemplateId,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }
      });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param createDepartmentCode 登録組織コード
   */
  public detail(pkeyId: string, createDepartmentCode: string) {
    // 詳細画面表示
    this.itemDetailComponent.initial(pkeyId, createDepartmentCode);
  }

  /**
   * 新規登録画面表示
   */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  public delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_ITEM,
      this.columnOrder[0].header,
      pkeyId
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施
      this.searchResult();

      return;
    }

    // 販促資材マスタ一覧取得処理(画面用)
    this.dbOperationService
      .getSingleData(
        API_URL_ITEM,
        this.searchResultsTemplateId,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          var indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);
        }
      });
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATE_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      ITEM_CONSTANT.TARGET_TABLE,
      fileName,
      API_URL_ITEM,
      (this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OFFICE || this.loginUser.department_type == DEPARTMENT_TYPE.TYPE_OTHER)
        ? 
          ITEM_TEMPLATE.OFFICE_OTHER_CSV_TEMPLATE_ID
        : 
          ITEM_TEMPLATE.CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }
}
